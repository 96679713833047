/*eslint-disable */

import { login, logout } from './login';
import { updateSettings } from './updateSettings';

// const baseURL = 'http://127.0.0.1:3000/';
const baseURL = 'https://mighty-beyond-35565.herokuapp.com/';

const loginForm = document.querySelector('#form__login');
const logOutBtn = document.querySelector('#btn-logout');
const userDataForm = document.querySelector('.form-user-data');
const userPasswordForm = document.querySelector('.form-user-password');

const formReportDailyStats = document.querySelector('#formReportDailyStats');
const formReportVerticals = document.querySelector('#formReportVerticals');
const formReportSummary = document.querySelector('#formReportSummary');
const formReportGeoBreakout = document.querySelector('#formReportGeoBreakout');
const formReportPubBreakout = document.querySelector('#formReportPubBreakout');
const formReportConversionsBySku = document.querySelector(
  '#formReportConversionsBySku'
);
const formReportMegaBundles = document.querySelector('#formReportMegaBundles');

const formAddMegaBundles = document.querySelector('#formAddMegaBundles');

const formAdminUpdateConversions = document.querySelector(
  '#formAdminUpdateConversions'
);

if (loginForm)
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (userDataForm) {
  userDataForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    await updateSettings({ name, email }, 'data');
  });
}

if (userPasswordForm) {
  userPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Updating...';
    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'password'
    );

    document.querySelector('.btn--save-password').textContent = 'Save password';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
  });
}

if (formReportDailyStats)
  formReportDailyStats.addEventListener('submit', (e) => {
    e.preventDefault();
    const startDate = document.getElementById(
      'reportDailyStatsStartDate'
    ).value;
    location.assign('/reports/daily-stats/' + startDate);
  });

if (formReportVerticals) {
  $('#reportVerticalsStartDate').on('change', function () {
    $('#reportVerticalsEndDate').attr('min', $(this).val());
  });
  formReportVerticals.addEventListener('submit', (e) => {
    e.preventDefault();
    // console.log('CLICKED');
    const startDate = document.getElementById('reportVerticalsStartDate').value;
    const endDate = document.getElementById('reportVerticalsEndDate').value;
    // console.log('FORM:', startDate, endDate);
    location.assign(
      '/reports/verticals?startDate=' + startDate + '&endDate=' + endDate
    );
  });
}

if (formReportSummary) {
  $('#reportSummaryStartDate').on('change', function () {
    $('#reportSummaryEndDate').attr('min', $(this).val());
  });
  formReportSummary.addEventListener('submit', (e) => {
    e.preventDefault();
    const startDate = document.getElementById('reportSummaryStartDate').value;
    const endDate = document.getElementById('reportSummaryEndDate').value;
    const status = document.getElementById('reportSummarySelectStatus').value;
    location.assign(
      '/reports/summary?startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&status=' +
        status
    );
  });
}

if (formReportGeoBreakout)
  formReportGeoBreakout.addEventListener('submit', (e) => {
    e.preventDefault();
    const month = document.getElementById('reportGeoBreakoutSelectMonth').value;
    location.assign('geobreakout?month=' + month);
  });

if (formReportPubBreakout)
  formReportPubBreakout.addEventListener('submit', (e) => {
    e.preventDefault();
    const month = document.getElementById('reportPubBreakoutSelectMonth').value;
    location.assign('publisherbreakout?month=' + month);
  });

if (formReportConversionsBySku) {
  $('#reportConversionsBySkuStartDate').on('change', function () {
    $('#reportConversionsBySkuEndDate').attr('min', $(this).val());
  });
  formReportConversionsBySku.addEventListener('submit', (e) => {
    e.preventDefault();
    const startDate = document.getElementById(
      'reportConversionsBySkuStartDate'
    ).value;
    const endDate = document.getElementById(
      'reportConversionsBySkuEndDate'
    ).value;
    const businessUnit = document.getElementById(
      'reportConversionsBySkuSelectBusinessUnit'
    ).value;
    const status = document.getElementById(
      'reportConversionsBySkuSelectStatus'
    ).value;
    location.assign(
      '/reports/skus?startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&businessUnit=' +
        businessUnit +
        '&status=' +
        status
    );
  });
}

if (formReportMegaBundles) {
  $('#reportMegaBundlesStartDate').on('change', function () {
    $('#reportMegaBundlesEndDate').attr('min', $(this).val());
  });
  formReportMegaBundles.addEventListener('submit', (e) => {
    e.preventDefault();
    const startDate = document.getElementById(
      'reportMegaBundlesStartDate'
    ).value;
    const endDate = document.getElementById('reportMegaBundlesEndDate').value;
    const status = document.getElementById(
      'reportMegaBundlesSelectStatus'
    ).value;
    const name = document.getElementById('reportMegaBundlesName').value;
    location.assign(
      '/reports/megabundles?startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&status=' +
        status +
        '&name=' +
        name
    );
  });
}

if (formAddMegaBundles)
  formAddMegaBundles.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('addMegaBundleName').value;
    const skuText = document.getElementById('addMegaBundleSkuText').value;
    const skuNumeric = document.getElementById('addMegaBundleSkuNumeric').value;
    const basePriceTier1 = document.getElementById(
      'addMegaBundlePriceTier1'
    ).value;
    const basePriceTier2 = document.getElementById(
      'addMegaBundlePriceTier2'
    ).value;
    const basePriceTier3 = document.getElementById(
      'addMegaBundlePriceTier3'
    ).value;
    const startDate = document.getElementById('addMegaBundleStartDate').value;
    const endDate = document.getElementById('addMegaBundleEndDate').value;
    location.assign(
      'megabundle?name=' +
        name +
        '&skuText=' +
        skuText +
        '&skuNumeric=' +
        skuNumeric +
        '&basePriceTier1=' +
        basePriceTier1 +
        '&basePriceTier2=' +
        basePriceTier2 +
        '&basePriceTier3=' +
        basePriceTier3 +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate
    );
  });

if (formAdminUpdateConversions)
  formAdminUpdateConversions.addEventListener('submit', (e) => {
    e.preventDefault();
    const requestDate = document.getElementById(
      'adminUpdateConversionsDate'
    ).value;
    console.log('Update conversions for the following date:', requestDate);
    location.assign('update/' + requestDate);
  });
